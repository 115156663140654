import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from "@angular/fire/storage";
import { map } from 'rxjs/operators';
import { Menu, Img, Chat } from "../interface/colecciones";

@Injectable({
  providedIn: 'root'
})
export class FirebaseService {

  constructor(
    private db:AngularFirestore,
    private dbST:AngularFireStorage
  ) { }

  getMenu(){
    return  this.db.collection<Menu>('Menu').snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;         
          return {id, ...data};
        });
      })
    );
  }

  obtenerImg(){
    return  this.db.collection<Img>('Img').snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;         
          return {id, ...data};
        });
      })
    );
  }

  obtenerChats(){
    return  this.db.collection<Chat>('Chat').snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;         
          return {id, ...data};
        });
      })
    );
  }

  agregarDatos(nameColection, todo){
    return this.db.collection(nameColection).add(todo)
    .catch(function (err) { 
      return alert(err); 
     });
  }
}
