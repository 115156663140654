// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  firebaseConfig : {
   apiKey: "AIzaSyAAcZ3lyvMAazw0dbn4DHu-56ZbZO9NcgE",
    authDomain: "nres-55f42.firebaseapp.com",
    databaseURL: "https://nres-55f42.firebaseio.com",
    projectId: "nres-55f42",
    storageBucket: "nres-55f42.appspot.com",
    messagingSenderId: "820029411109",
    appId: "1:820029411109:web:b1ea79ba2c5eedf0bb54cc",
    measurementId: "G-XXB89WNRE3"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
